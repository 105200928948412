<template>
	<v-container v-intersect="resetForm" class="pa-0 pt-2">
		<v-window v-model="step">
		
			<v-window-item :value="1">
				
				<v-card elevation="0" min-height="400">
					<v-row justify="center" class="mt-0">
						<v-img max-width="132" class="rounded-circle" height="132" :src="require('@/assets/images/icons/seamanblank.jpg')" alt="Seafarer Image"></v-img>
					</v-row>
					
					<v-row no-gutters justify="center" class="mt-1">
						<v-col
							style="min-height:42px;"
							cols="12"
						>
							<v-alert
								v-model="isLoginError"
								type="error"
								border="left"
								color="red lighten-2"
								dense=dense
								class="ma-0"
							>
								These credentials does not match
							</v-alert>
							
							<v-alert
								v-model="isNetworkError"
								type="warning"
								border="left"
								color="orange lighten-2"
								dense=dense
							>
								Something wrong, contact the administrator.
							</v-alert>
							
						</v-col>
					</v-row>
					
					<v-form
						ref="loginForm"
						v-model="loginValid"
						lazy-validation
					>
						
						<v-row no-gutters>
							<v-col cols="12">
								<v-text-field
									v-model="loginUser"
									v-on:keydown="clearAuthStatus"
									v-on:keyup.enter="validateLogin"
									:rules="loginUserRules"
									prepend-icon="mdi-face"
									label="Username | Email"
									required
								></v-text-field>
							</v-col>
							
							<v-col cols="12">
								<v-text-field
									v-model="loginPassword"
									v-on:keydown="clearAuthStatus"
									v-on:keyup.enter="validateLogin"
									:rules="loginPasswordRules"
									type="password"
									id="loginPasswordField"
									label="Password"
									prepend-icon="mdi-lock"
									required
								></v-text-field>
							</v-col>
							
							<v-col cols="12" class="text-left mb-5">
								<v-btn
									color="primary"
									text
									x-small
									class="pa-0"
									@click="userAccount = ''; step = 2;"
								>
									Forgot Password?
								</v-btn>
							</v-col>
						
							<v-col class="text-center" cols="12">
								<v-btn
									min-width="100"
									:disabled="!loginValid"				  
									:loading="isLoginLoading"
									color="primary"
									@click="validateLogin"
								>
									LOGIN
								</v-btn>
							</v-col>
						</v-row>
								
					</v-form>
				</v-card>
			</v-window-item>
			
			<v-window-item :value="2">
				<v-card elevation="0" min-height="400">
					<v-card-title>
						<span class="mx-auto text-center">ACCOUNT RECOVERY(1/3)</span>
					</v-card-title>
					
					<v-card-text class="mb-3">
						<v-row no-gutters>
							<v-col class="mx-auto text-center body-1">
								Enter your username or email<br/>&nbsp;<span style="color:white;">.</span>
							</v-col>
						
							<v-col cols="12">
								<v-text-field
									class="centered-input"
									v-model="userAccount"
									placeholder="Username | Email"
									:error="accountRecoverError"
									:error-messages="accountRecoverErrorMsg"
									@focus="accountRecoverError = false; accountRecoverErrorMsg = '';"
									@keydown="accountRecoverError = false; accountRecoverErrorMsg = '';"
								></v-text-field>
							</v-col>
						
							<v-col cols="12" class="text-center">
								<v-btn
									color="primary"
									@click="recoverAccount"
									:loading="btnRecoverAccount"
								>
									CONTINUE
								</v-btn>
							</v-col>
							
							<v-col cols="12" class="text-center text-caption pt-12">
								Can't remember your account? 
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											v-on="on"
											target="_self"
											href="javascript:void(0)"
											class="text-decoration-none"
											@click="clearForm(); step = 6;"
										>Try another way</a>
									</template>
									Click to try another way
								</v-tooltip>
							</v-col>
						</v-row>
					</v-card-text>
					
					<v-card-actions>
						<v-row>
							<v-col cols="12" class="text-center">
								<v-btn depressed @click="step = 1">
									BACK
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-window-item>
			
			<v-window-item :value="3">
				
				<v-card elevation="0" min-height="400">
					<v-card-title>
						<span class="mx-auto">ACCOUNT RECOVERY(2/3)</span>
					</v-card-title>
					
					<v-card-text>
						<v-row>
							<v-col class="body-1 text-center px-0">
								Please enter the 6 character code we sent to <br/><strong>{{ emailRecover }}</strong>
							</v-col>
						</v-row>
						
						<v-row no-gutters>
							<v-col
								cols="8"
								class="mx-auto text-center"
							>
								<v-text-field
									class="centered-input uppercase-input"
									v-model="verificationCode"
									placeholder="VERIFICATION CODE"
									:error="confirmCodeError"
									:error-messages="confirmCodeErrorMsg"
									@focus="confirmCodeError = false; confirmCodeErrorMsg = '';"
									@keydown="confirmCodeError = false; confirmCodeErrorMsg = '';"
								></v-text-field>
							</v-col>
						
							<v-col
								cols="12"
								class="text-center"
							>
								<v-btn
									min-width="100"
									color="primary"
									@click="confirmCode"
									:loading="btnConfirmCode"
								>
									CONFIRM
								</v-btn>
							</v-col>
							
							<v-col cols="12" class="text-center pt-4">
								<v-btn
									v-if="countDown > 0"
									disabled
									text
								>
									RESEND CODE ({{ countDown }})
								</v-btn>
								
								<v-btn
									v-else
									color="primary"
									:loading="btnResendCode"
									text
									@click="resendCode()"
								>
									RESEND CODE
								</v-btn>
							</v-col>
							
							<v-col cols="12" class="text-center text-caption pt-4">
								Don't have access to your email? 
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											v-on="on"
											target="_self"
											href="javascript:void(0)"
											class="text-decoration-none"
											@click="clearForm(); step = 6;"
										>Try another way</a>
									</template>
									Click to try another way
								</v-tooltip>
							</v-col>
						</v-row>
					</v-card-text>
					
					<v-card-actions class="pt-2">
						<v-row no-gutters>
							<v-col cols="mx-auto" class="text-center">
								<v-btn depressed @click="step = 1">
									CANCEL
								</v-btn>
							</v-col>
						</v-row>
						
					</v-card-actions>
				</v-card>
			</v-window-item>
			
			<v-window-item :value="4">
			
				<v-card elevation="0" min-height="400">
					<v-card-title>
						<span class="mx-auto">ACCOUNT RECOVERY(3/3)</span>
					</v-card-title>
					
					<v-card-text class="mb-6">
						<v-row no-gutters>
							<v-col class="text-center body-1">
								Enter your new password<br/>&nbsp;<span style="color:white;">.</span>
							</v-col>
						
							<v-col
								cols="12"
								class="mx-auto text-center">
								<v-text-field
									class="centered-input"
									placeholder="New Password"
									v-model="newPassword"
									validate-on-blur
									:rules="[rules.required, rules.min]"
									:type="showNewPassword ? 'text' : 'password'"
									:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showNewPassword = !showNewPassword"
								></v-text-field>
							</v-col>
							
							<v-col
								cols="12"
								class="mx-auto text-center">
								<v-text-field
									class="centered-input"
									placeholder="Confirm Password"
									v-model="confirmPassword"
									validate-on-blur
									:rules="[rules.required, rules.min, passwordMatch]"
									:type="showConfirmPassword ? 'text' : 'password'"
									:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showConfirmPassword = !showConfirmPassword"
								></v-text-field>
							</v-col>
							
							<v-col
								cols="12"
								class="mx-auto text-center mt-4">
								<v-btn
									min-width="100"
									color="primary"
									@click="resetPassword"
									:loading="btnResetPassword"
								>
									RESET
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
					
					<v-card-actions>
						<v-row no-gutters>
							<v-col class="mx-auto text-center">
								<v-btn depressed @click="step = 1">
									CANCEL
								</v-btn>
							</v-col>
						</v-row>
						
					</v-card-actions>
				</v-card>
				
			</v-window-item>
			
			<v-window-item :value="5">
				<v-card elevation="0" min-height="400">
					<v-row no-gutters>
						<v-col cols="12" class="mt-16 text-center">
							<v-alert
								text
								color="success"
								class="px-5 subtitle-1 font-regular"
							>
								<font class="success--text">You have successfully reset your password. You may now login with your new password.</font>&nbsp;
								<v-icon small color="success">mdi-party-popper</v-icon>
							</v-alert>	
						</v-col>
						
						<v-col cols="12" class="text-center">
							<v-btn outlined @click="step = 1" color="primary">
								LOGIN ACCOUNT
							</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-window-item>
			
			<v-window-item :value="6">
				<v-card elevation="0" min-height="400">
					<v-card-text>
						<v-form
							ref="verifyForm"
							v-model="verifyValid"
							lazy-validation
						>
							<v-row no-gutters>
							
								<v-col cols="12" class="text-center body-2">
									To help you find and recover your account, please fill up the form below. 
									<br/>This will take days to find and verify your account we will ask additional requirements if needed.
									<br/>For faster transaction please visit our office and bring valid I.D.
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										v-model="verifyAccountFullName"
										hint="Last Name, First Name, Middle Name"
										persistent-hint
										label="Full Name (Dela Cruz, Juan Jr., Luna)"
										:rules="rulesFullName"
										validate-on-blur
										class="pt-2"
									></v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										v-model="verifyAccountDOB"
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DOBType"
										label="Date of Birth"
										:rules="rulesDOB"
										validate-on-blur
										@focus="DOBType = 'date'"
										@blur="DOBType = (isValidDate(verifyAccountDOB))? 'date' : 'text'"
										class="pt-2"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" v-show="contactEmail">
									<v-text-field
										v-model="verifyAccountEmail"
										ref="inputEmail"
										label="Email Address"
										hint="Mobile Number/Email to contact you"
										persistent-hint
										:rules="rulesEmail"
										validate-on-blur
										class="pt-2"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onTooltipEmail }">
													<v-icon class="pa-0 ma-0" left v-on="onTooltipEmail" @click="$refs.inputEmail.resetValidation(); contactEmail = !contactEmail;">mdi-email</v-icon>
												</template>
												<small>Switch</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" v-show="!contactEmail">
									<v-text-field
										v-model="verifyAccountMobile"
										v-mask="maskMobile"
										ref="inputMobile"
										label="Mobile Number"
										hint="Mobile Number/Email to contact you"
										persistent-hint
										:rules="rulesMobile"
										validate-on-blur
										class="pt-2"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onTooltipMobile }">
													<v-icon class="pa-0 ma-0" left v-on="onTooltipMobile" @click="$refs.inputMobile.resetValidation(); contactEmail = !contactEmail;">mdi-cellphone-android</v-icon>
												</template>
												<small>Switch</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					
					<v-card-actions class="py-0">
						<v-row justify="space-between">
							<v-col cols="auto">
								<v-btn depressed @click="resetVerifyForm(); step = 1">
									CANCEL
								</v-btn>
							</v-col>
							
							<v-col cols="auto">
								<v-btn
									color="primary"
									:disbled="!verifyValid"
									:loading="btnVerifyAccount"
									@click="verifyAccount"
								>
									SUBMIT
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-window-item>
			
			<v-window-item :value="7">
				<v-card elevation="0" min-height="400">
					<v-row no-gutters>
						<v-col cols="12" class="mt-16 text-center">
							<v-alert
								text
								color="success"
								class="px-5 subtitle-1 font-regular"
							>
								<font class="success--text">Message received. <br>We'll get back on you as soon as possible.<br/>Thank you</font>
							</v-alert>	
						</v-col>
						
						<v-col cols="12" class="text-center">
							<v-btn outlined @click="step = 1" color="primary">
								BACK TO LOGIN
							</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-window-item>
		</v-window>
	</v-container>
</template>

<script>
import {mask} from 'vue-the-mask';

import { PAGE_ERROR_CONNECTION, PAGE_SEND } from '@/store/actions/page';
import { AUTH_REQUEST, AUTH_CLEAR } from '@/store/actions/auth';

export default {
	directives: {mask},
	data () {
		return {
			step: 1,
			userAccount: "",
			verificationCode: "",
			emailRecover: "",
			newPassword: "",
			confirmPassword: "",
			countDown : 0,
			DOBType: 'text',
			contactEmail: true,
			
			maskMobile				: '####-####-###',
			
			verifyAccountFullName	: '',
			verifyAccountDOB		: '',
			verifyAccountMobile		: '',
			verifyAccountEmail		: '',
			verifyValid				: true,
			
			showNewPassword			: false,
			showConfirmPassword		: false,
			
			btnRecoverAccount		: false,
			btnConfirmCode			: false,
			btnResendCode			: false,
			btnResetPassword		: false,
			btnVerifyAccount		: false,
			
			accountRecoverError		: false,
			accountRecoverErrorMsg	: '',
			confirmCodeError		: false,
			confirmCodeErrorMsg		: '',
			
			loginUser: "",
			loginPassword: "",
			loginValid: true,
			loginLoading: false,
			loginUserRules: [
				v => !!v || 'Username/Email is required',
			],
			loginPasswordRules: [
				v => !!v || 'Password is required',
			],
			rules: {
				required: v => !!v || 'Password is required',
				min		: v => v.length >= 8 || 'Min 8 characters',
			},
			rulesFullName:[
				v => !!v || 'Fullname is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (!!v && this.stringLength(v) <= 50) || 'Must be less than 100 characters',
			],
			rulesDOB:[
				v => !!v || 'Date Applied is required',
			],
			rulesEmail: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'Invalid email',
			],
			rulesMobile: [
				v => !!v || "Mobile number is required",
				v => /[0][9][0-9]{2}[-][0-9]{4}[-][0-9]{3}$/.test(v) || 'Invalid mobile number',
			],
		}
	},
	methods: {
		resetForm(){
			this.$refs.loginForm.resetValidation();
		},
		clearForm(){
			this.verifyAccountDOB = '';
			this.verifyAccountEmail = '';
			this.verifyAccountMobile = '';
			this.verifyAccountFullName = '';
		},
		validateLogin(){
			if (this.$refs.loginForm.validate()) {
				let { loginUser, loginPassword } = this;
				this.$store.dispatch(AUTH_REQUEST, { loginUser, loginPassword }).then(() => {
					if( typeof this.$route.name !== 'undefined' && this.$route.name != '' )this.$router.push('/');
				});
			}
		},
		clearAuthStatus(){
			this.$store.dispatch(AUTH_CLEAR);
		},
		resetVerifyForm(){
			this.$refs.verifyForm.resetValidation();
		},
		verifyAccount(){
		//	if (this.$refs.verifyForm.validate()) {
				this.btnVerifyAccount = true;
			
				this.$http.post('recover/verifyAccount', {
					fullName: this.verifyAccountFullName,
					DOB		: this.verifyAccountDOB,
					mobile	: this.verifyAccountMobile,
					email	: this.verifyAccountEmail
				}).then(() => {
					this.step = 7;
					
					this.verifyAccountFullName	= '';
					this.verifyAccountDOB		= '';
					this.verifyAccountMobile	= '',
					this.verifyAccountEmail		= '',
			
					this.DOBType = 'text';
				}).catch(() => {
					this.$store.dispatch(PAGE_ERROR_CONNECTION);
				}).finally(() => {
					this.btnVerifyAccount = false;
				});
				
		//	}
		},
		recoverAccount(){
			this.btnRecoverAccount = true;
			
			this.$http.post('recover/checkAccount', {
				user: this.userAccount
			}).then((resp) => {
				if( typeof resp.data.error !== 'undefined' ){
					this.accountRecoverError = true;
					this.accountRecoverErrorMsg = resp.data.error;
				}else{
					this.emailRecover = resp.data.email;
					this.verificationCode = "";
					this.step = 3;
					
					this.countDown = 60;
					this.countDownTimer();
				}
			}).catch(() => {
				this.$store.dispatch(PAGE_ERROR_CONNECTION);
			}).finally(() => {
				this.btnRecoverAccount = false;
			});
			
		},
		confirmCode(){
			this.btnConfirmCode = true;
			
			this.$http.post('recover/confirmCode', {
				user	: this.userAccount,
				code	: this.verificationCode
			}).then((resp) => {
				if( resp.data.verified == 1 ){
					this.newPassword = this.confirmPassword = "";
					this.verificationCode = "";
					this.step = 4;
				}else{
					this.confirmCodeError = true;
					this.confirmCodeErrorMsg = 'Code not match';
				}
			}).catch(() => {
				this.$store.dispatch(PAGE_ERROR_CONNECTION);
			}).finally(() => {
				this.btnConfirmCode = false;
			});
			
		},
		resendCode(){
			
			this.btnResendCode = true;
			
			this.$http.post('recover/resendCode', {
				user: this.userAccount
			}).then(() => {
				this.countDown = 60;
				this.countDownTimer();
				this.$store.dispatch(PAGE_SEND);
			}).catch(() => {
				this.$store.dispatch(PAGE_ERROR_CONNECTION);
			}).finally(() => {
				this.btnResendCode = false;
			});
			
		},
		resetPassword(){
			this.btnResetPassword = true;
			
			this.$http.post('recover/resetPassword', {
				user	: this.userAccount,
				password: this.newPassword
			}).then(() => {
				this.step = 5;
				this.newPassword = this.confirmPassword = "";
			}).catch(() => {
				this.$store.dispatch(PAGE_ERROR_CONNECTION);
			}).finally(() => {
				this.btnResetPassword = false;
			});
		},
		countDownTimer() {
			if(this.countDown > 0) {
				setTimeout(() => {
					this.countDown -= 1
					this.countDownTimer()
				}, 1000)
			}
		},
		isValidDate(dateString) {
			if(dateString == null)return false;
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
	},
	computed:{
		passwordMatch() {
			return () => (this.newPassword === this.confirmPassword) || 'Password must match'
		},
		isLoginLoading: {
			get: function(){
				return this.$store.getters.authStatus == 'loading';
			},
			
		},
		isLoginError: {
			get: function(){
				return this.$store.getters.authStatus == 'error';
			},
			
		},
		isNetworkError: {
			get: function(){
				return this.$store.getters.authStatus == 'networkerror';
			},
			
		},
	},
}
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
	
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>